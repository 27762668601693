export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')];

export const server_loads = [0,2,3];

export const dictionary = {
	"/(main)": [4,[2]],
	"/(main)/(cp7eleven)/account-alerts": [5,[2,3]],
	"/(main)/benefits": [10,[2]],
	"/(main)/(cp7eleven)/contact-us": [6,[2,3]],
	"/(main)/credit-alerts": [11,[2]],
	"/(main)/credit-builder": [12,[2]],
	"/(main)/credit-protection": [13,[2]],
	"/(main)/(cp7eleven)/credit-report-history": [7,[2,3]],
	"/(main)/credit-report": [14,[2]],
	"/(main)/credit-score": [15,[2]],
	"/(main)/(cp7eleven)/dashboard": [8,[2,3]],
	"/(main)/debt-analysis": [16,[2]],
	"/(main)/debt-manager-testing": [17,[2]],
	"/demoAccess": [34],
	"/(main)/identity-protect": [18,[2]],
	"/(main)/login": [19,[2]],
	"/(main)/logout": [~20,[2]],
	"/(main)/(cp7eleven)/my-account": [9,[2,3]],
	"/(main)/neighborhood-watch": [21,[2]],
	"/oktaClient": [36],
	"/okta": [35],
	"/(main)/overview": [22,[2]],
	"/(main)/privacy-policy": [23,[2]],
	"/(main)/privacy-protect": [24,[2]],
	"/(main)/score-factors": [25,[2]],
	"/(main)/score-insights": [26,[2]],
	"/(main)/score-sim-exp": [28,[2]],
	"/(main)/score-sim": [27,[2]],
	"/(main)/settings": [29,[2]],
	"/(main)/signup": [30,[2]],
	"/(main)/subscription-manager": [31,[2]],
	"/(main)/terms-of-service": [32,[2]],
	"/tools/verification": [37],
	"/(main)/user-token": [33,[2]]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};